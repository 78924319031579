'use client';
import {useEffect} from 'react'
import {fatal} from 'src/actions/alerting'
import Config from 'src/config'
import once from 'sugar/function/once'
import Button from '@mui/material/Button';
import GeneralErrorView from '@agp/ui/sections/error/general-error-view'

const ErrorBoundary = ({error, reset})=> {
	if (process.env.NODE_ENV === 'production') {
		reportOnce(`[${Config().LOCATION_NAME}] ${error.message}`)
	}
	const buttons = (
		<>
		  <a href={`tel:${Config().COMPANY_PHONE}`} style={{ textDecoration: 'none' }}>
			<Button  size="large" variant="contained"  color="primary">Call us directly</Button>
		  </a>
		</>
	  )
	return <GeneralErrorView buttons={buttons} />
}

const reportOnce = once(fatal)

export default ErrorBoundary